<template>
  <div class="loginPage">
    <HeadInfo :is-login="true" />
    <div class="loginMain">
      <div class="loginForget">
        <div>
          <img
            src="../../assets/img/forgotuser.png"
            width="216"
            alt="forgotuser"
            :style="visibility"
          />
        </div>
        <div>
          <div class="loginTitle">
            {{ $g("forgetPassword") }}
          </div>
          <div class="loginDescription" v-html="description" />
        </div>
      </div>
      <div class="loginContent">
        <k-alert-msg ref="lblMsg" :is-html="true" :display-time="true" />
        <KValidationObserver ref="observer" v-if="verifyStatus">
          <k-form-group
            label-class="require-mark"
            label-suffix="userName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, max: 256 }"
              v-slot="{ errors }"
              slim
              name="userName"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="userName"
                v-model="form.userName"
                :aria-label="$g('userName')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="newPassword"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, password: true, max: 256 }"
              v-slot="{ errors }"
              slim
              name="newPassword"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="newPassword"
                v-model="form.password"
                type="password"
                :aria-label="$g('newPassword')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="confirmPassword"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, confirmed: 'newPassword', max: 256 }"
              v-slot="{ errors }"
              slim
              name="confirmPassword"
            >
              <k-alert :show="errors.length > 0" variant="warning">
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="confirmPassword"
                v-model="confirmPassword"
                @paste.native.capture.prevent="() => false"
                type="password"
                :aria-label="$g('confirmPassword')"
              />
            </KValidationProvider>
          </k-form-group>
          <k-form-group
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <div class="alert alert-warning" v-if="isManMachine">
              {{ $g("validate.required") }}
            </div>
            <ReCaptcha
              @validate="validate"
              @expired="resetCaptcha"
              style="margin-bottom: 25px"
            />
          </k-form-group>
        </KValidationObserver>

        <k-form-group
          v-if="verifyStatus"
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapBtnCenter"
        >
          <k-button type="button" @click="save"> save </k-button>
        </k-form-group>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>
<script>
import {
  changePassWord,
  getPageDesc,
  changePasswordVerifyLinkDead,
} from "@/api/login";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import {
  storeage,
  ReCaptcha,
  repResult,
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
} from "fe-infrastractures";

export default {
  components: {
    HeadInfo,
    FooterInfo,
    ReCaptcha,
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    let visibility = getConfigVal(
      curPageConfigAndDefaultPageConfig,
      "showPic",
      "vVisibility"
    );
    return {
      visibility,
      description: "",
      isManMachine: false,
      isValidateRecaptcha: false,
      pageName: "ForgotPassword-EnterNewPassword",
      form: {
        userName: "",
        password: "",
        code: "",
      },
      confirmPassword: "",
      localEnv: storeage.getSystemGeneralInfo()?.isLocal,
      verifyStatus: this.$route.query.verifyStatus != 3,
    };
  },
  methods: {
    async save() {
      const isValid = await this.validateBeforeSave();
      if (isValid) {
        changePassWord(this.form).then((resp) => {
          if (resp.data.status == repResult.success) {
            this.$router.push({
              name: "submit-success",
              query: { pageName: "ForgotPassword-SubmitSuccessful" },
            });
          } else {
            this.$refs.lblMsg.message(repResult.faild, resp.message);
          }
        });
      }
    },
    validate(success) {
      this.isValidateRecaptcha = success;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha() {
      this.isValidateRecaptcha = false;
    },

    async validateBeforeSave() {
      var isValid = await this.$refs.observer.validate();
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
        isValid = isValid && this.isValidateRecaptcha;
      }
      return isValid;
    },

    showMessage() {
      if (!this.verifyStatus) {
        this.$refs.lblMsg.message(repResult.faild, this.$route.params.message);
      }
    },
  },
  mounted() {
    this.form.code = this.$route.query.code;
    getPageDesc(this.pageName).then((resp) => {
      this.description = resp.data.description;
    });
    this.showMessage();
  },
  beforeRouteEnter: async (to, from, next) => {
    let queryPost = { code: to.query.code };
    let data = await changePasswordVerifyLinkDead(queryPost);
    if (data.data.status == 3) {
      to.query.verifyStatus = 3;
      to.params.message = data.data.info;
    }
    next();
  },
};
</script>
